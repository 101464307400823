import { store } from '../../index';
import  { PLATFORMS } from '../_helpers/constants';
import { productsService as nps } from '../_services/nuvemshop/products.service';
import { productsService as tps} from '../_services/tray/products.service';
import { mapNuvemProduct, mapNuvemProductArrayWithVariants } from '../_helpers/mappers/nuvemshop';
import { mapTrayProduct, mapTrayProductArray } from '../_helpers/mappers/tray';
import _ from 'lodash';

export const productsActions = {
    getProducts,
    getProductById,
    searchProducts
};

function getProducts(filters) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.getProducts;
        mapperMethod = mapNuvemProductArrayWithVariants;
    } else {
        queryMethod = tps.getProducts;
        mapperMethod = mapTrayProductArray;
    }

    return () => {
        return queryMethod(filters).then(response => {
            let data = null;
            if(platform === PLATFORMS.NUVEMSHOP) { 
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.headers.get('x-total-count')
                }
            } else {
                data = {
                    products: mapperMethod(response.Products),
                    totalCount: _.get(response, 'paging.total', 0)
                }
            }
            
            return data;
        })
    };
}

function getProductById(productId) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.getProductById;
        mapperMethod = mapNuvemProduct;
    } else {
        queryMethod = tps.getProductById;
        mapperMethod = mapTrayProduct;
    }

    return () => {
        return queryMethod(productId).then(response => {
            return mapperMethod(response);
        })
    };
}

function searchProducts(filters, query) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.searchProducts;
        mapperMethod = mapNuvemProductArrayWithVariants;
    } else {
        queryMethod = tps.searchProducts;
        mapperMethod = mapTrayProductArray;
    }

    return () => {
        return queryMethod(filters, query).then(response => {
            let data = null;
            if(platform === PLATFORMS.NUVEMSHOP) { 
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.headers.get('x-total-count')
                }
            } else {
                data = {
                    products: mapperMethod(response.Products),
                    totalCount: _.get(response, 'paging.total', 0)
                }
            }
            
            return data;
        })
    };
}