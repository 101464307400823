import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Grid, Modal, InputLabel, Paper, CircularProgress } from '@material-ui/core';
import MaterialTable from "material-table";
import tableIcons from '../_helpers/materialTableIcons';
import _ from 'lodash';
import { productsActions } from '../../Editor/_actions/products.actions';
import { PLATFORMS } from '../../Editor/_helpers/constants';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 22,
    width: '100%'
  },
  modal: {
    position: 'absolute',
    width: '70%',
    padding: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  modalButton: {
      margin: 20
  },
  table: {
    boxShadow: 'none'
  }
}));

export default function ProductPicker({ onPicked, selectedProductId }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const platform = useSelector(state => state.StoreReducer.platform);
  
  useEffect(() => {
    if(!!selectedProductId) {
        setLoading(true);
        dispatch(productsActions.getProductById(selectedProductId))
            .then(response => {
                setSelectedProduct(response);
            })
            .catch(() => {
                setSelectedProduct(null);
            })
            .finally(() => {
                setLoading(false)
            })
    }

  }, [selectedProductId, dispatch]);

  const handleRowClick = (data) => {
    setShowModal(false);
    setSelectedProduct(data);

    if(onPicked) {
      onPicked(data)
    }
  }

  return (
    <div>
        <Grid container>
            <InputLabel shrink>Produto</InputLabel>
            <Button
                className={classes.button}
                onClick={() => setShowModal(true)}
                variant="outlined"
            >
              { loading ? 
                <CircularProgress size={20} /> :
                (_.isEmpty(selectedProduct) ? 'Selecionar produto' : _.get(selectedProduct, 'name', ''))
              }
            </Button>
        </Grid>

        <Modal
            disableAutoFocus
            disableEnforceFocus
            onClose={() => setShowModal(false)}
            open={showModal}
        >
            <Card className={classes.modal}>
              <MaterialTable
                  columns={[
                    { 
                        title: 'Imagem', 
                        field: 'images',
                        width: 100,
                        render: rowData => (
                        <img
                            style={{ height: 36 }}
                            src={_.get(rowData, 'images[0].imageUrl', require('../_assets/images/image_placeholder_350_350.png'))}
                        />
                        )
                    },
                    { title: 'Produto', field: 'name' }
                  ]}
                  components={{
                    Container: props => <Paper {...props} elevation={0}/>
                  }}
                  data={query =>
                    new Promise((resolve, reject) => {
                        const filters = platform === PLATFORMS.TRAY ? {
                            page: query.page + 1,
                            limit: query.pageSize,
                            sort: 'name_asc'
                        } : {
                            page: query.page + 1,
                            per_page: query.pageSize,
                            sort_by: 'alpha-ascending'
                        }

                        let queryMethod = null;
                        if(_.isEmpty(query.search)) {
                            queryMethod = productsActions.getProducts;
                        } else {
                            queryMethod = productsActions.searchProducts;
                        }

                        dispatch(queryMethod(filters, query.search))
                            .then(response => {
                                resolve({
                                    data: response.products,
                                    page: query.page,
                                    totalCount: response.totalCount
                                })
                            })
                            .catch(() => {
                                resolve({
                                    data: [],
                                    page: 0,
                                    totalCount: 0
                                })
                            })
                    })
                  }
                  localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}'
                    },
                    toolbar: {
                      searchPlaceholder: 'Buscar',
                      searchTooltip: 'Buscar'
                    },
                    body: {
                        emptyDataSourceMessage: 'Sem produtos para exibir'
                    }
                  }}
                  icons={tableIcons}
                  onRowClick={(e, data) => handleRowClick(data)}
                  options={{
                    debounceInterval: 500,
                    pageSize: 5,
                    pageSizeOptions: [],
                    header: false
                  }}
                  title="Produtos"
              />
              <Grid container justify='center'>
                  <Button color="secondary" size="small" className={classes.modalButton} onClick={() => setShowModal(false)}>
                      Cancelar
                  </Button>
              </Grid>
            </Card>
        </Modal>
    </div>
  );
}