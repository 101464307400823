import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ProductHalfCard from '../_components/ProductHalfCard/ProductHalfCard';
import ProductFullCard from '../_components/ProductFullCard/ProductFullCard';
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';
import { productsActions } from '../../Editor/_actions/products.actions';
import _ from 'lodash';
import shortid from 'shortid';
import { PLATFORMS } from '../../Editor/_helpers/constants';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#fff',
        cursor: 'grab',
        width: '100%'
    },
    filterBar: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 10px'
    },
    title: (props) =>({
        backgroundColor: _.get(props, 'options.titleBackgroundColor', '#000000'),
        color: _.get(props, 'options.titleFontColor', '#ffffff'),
        fontSize: 14,
        fontWeight: "bold",
        margin: 0,
        padding: 10,
        textAlign: 'center',
        width: '100%'
    }),
    icon: {
        height: 22,
        marginLeft: 20,
        width: 22
    },
    productCardsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
    }
}));

export default function CategoryScreen() {
  const dispatch = useDispatch();

  const selectedArea = useSelector(state => getSelectedArea(state));
  const data = selectedArea.sections.find(s => s.key === 'categorySettings');
  const platform = useSelector(state => state.StoreReducer.platform);
  const classes = useStyles({...data});
  const numberOfProducts = 10;

  const getEmptyProductsArray = () => {
    const idBase = shortid.generate();
    return Array.from({ length: numberOfProducts }, (_, i) => { return { id: `${idBase}-${i}`} } )
  }

  const [products, setProducts] = useState(getEmptyProductsArray());

  useEffect(() => {
    const defaultSorting = _.get(data, 'options.defaultSorting', null);
    const filters = platform === PLATFORMS.TRAY ? {
            limit: numberOfProducts,
            sort: _.isEmpty(defaultSorting) ? 'release_desc' : defaultSorting
        } : {
            per_page: numberOfProducts,
            sort_by: _.isEmpty(defaultSorting) ? 'created-descending' : defaultSorting
        }

    dispatch(productsActions.getProducts(filters))
        .then(response => {
            setProducts(response.products);
        })
        .catch(() => {
            setProducts(getEmptyProductsArray());
        })
  }, [data.options.defaultSorting, dispatch]);

  return (
    <div className={classes.root}>
        <p className={classes.title}>Título da Categoria</p>
        <div className={classes.filterBar}>
            <p>10 produtos encontrados</p>
            <div>
                <img src={require('../../Editor/_assets/images/icon_order.png')} className={classes.icon} alt="icon"/>
                {data.options.defaultView === 'grid' ?
                    <img src={require('../../Editor/_assets/images/icon_list_view.png')} className={classes.icon} alt="icon"/> :
                    <img src={require('../../Editor/_assets/images/icon_grid_view.png')} className={classes.icon} alt="icon"/>
                }
            </div>
        </div>

        <div className={classes.productCardsWrapper}>
            {products.map(product => {
                if(data.options.defaultView === 'grid') {
                    return <ProductHalfCard key={`phc-${product.id}`} product={product} />
                } else {
                    return <ProductFullCard key={`pfc-${product.id}`} product={product} />
                }
            })}
        </div>
    </div>
  );
}
