import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Select, TextField } from '@material-ui/core';
import DeleteSectionButton from '../../DeleteSectionButton';
import ColorPicker from '../../ColorPicker';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import CategoryPicker from '../../CategoryPicker';
import { PLATFORMS, NUVEM_PRODUCT_SORT_OPTIONS, TRAY_PRODUCT_SORT_OPTIONS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function ProductList({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.StoreReducer.platform);

  const sortOptions = 
    platform === PLATFORMS.TRAY ?
    TRAY_PRODUCT_SORT_OPTIONS() :
    NUVEM_PRODUCT_SORT_OPTIONS


  const getSortOptionHelperText = () => {
    const sortOption = sortOptions.find(opt => opt.value === data.options.defaultSorting);

    if(!!sortOption) {
      return sortOption.helperText;
    }

    return '';
  }

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>

            <ColorPicker 
              label='Cor do título' 
              initialColor={data.options.titleColor} 
              onChange={(color) => handleChange('options.titleColor', color)} 
            />

            <ColorPicker 
              label='Cor do subtítulo' 
              initialColor={data.options.subtitleColor} 
              onChange={(color) => handleChange('options.subtitleColor', color)} 
            />

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Título"
                  value={data.options.title || ''} 
                  onChange={(e) => handleChange('options.title', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Subtítulo"
                  value={data.options.subtitle || ''} 
                  onChange={(e) => handleChange('options.subtitle', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Alinhamento dos títulos</InputLabel>
                <Select
                    native
                    value={data.options.textAlign || ''}
                    onChange={(e) => handleChange('options.textAlign', e.target.value)}
                >
                    <option value='left'>Alinhar à esquerda</option>
                    <option value='center'>Centralizar</option>
                    <option value='right'>Alinhar à direita</option>
                </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Número de produtos</InputLabel>
                <Select
                    native
                    value={data.options.numberOfProducts}
                    onChange={(e) => handleChange('options.numberOfProducts', e.target.value)}
                >
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                    <option value='11'>11</option>
                    <option value='12'>12</option>
                </Select>
                <FormHelperText>Selecione o número de produtos que você deseja exibir</FormHelperText>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Ordenação</InputLabel>
                <Select
                    native
                    value={data.options.defaultSorting}
                    onChange={(e) => handleChange('options.defaultSorting', e.target.value)}
                    label="Ordenação"
                >
                  {sortOptions.map((sortOption, index) => {
                    return <option key={`so=${index}`} value={sortOption.value}>{sortOption.label}</option>
                  })}
                </Select>
                <FormHelperText>{getSortOptionHelperText()}</FormHelperText>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Visualização</InputLabel>
                <Select
                    native
                    value={data.options.defaultView}
                    onChange={(e) => handleChange('options.defaultView', e.target.value)}
                    label="Visualização"
                >
                    <option value='grid'>Grid</option>
                    <option value='list'>Lista</option>
                </Select>
            </FormControl>


            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Produtos</InputLabel>
                <Select
                    native
                    value={data.options.productsOrigin}
                    onChange={(e) => handleChange('options.productsOrigin', e.target.value)}
                >
                    <option value='general'>Exibir produtos a partir da listagem geral da loja</option>
                    <option value='category'>Exibir produtos a partir de uma categoria</option>
                </Select>
                <FormHelperText>Selecione da onde serão consultados os produtos a serem exibidos</FormHelperText>
            </FormControl>

            {data.options.productsOrigin ===  'category' &&
                <FormControl fullWidth className={classes.formControl}>
                    <CategoryPicker
                        selectedCategoryId={data.options.categoryId || ''}
                        onPicked={(category) => handleChange('options.categoryId', category.id)} 
                    />
                    <FormHelperText>Selecione a categoria que será consultada para exibir os produtos</FormHelperText>
                </FormControl>
            }

            <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                    control={
                        <Checkbox
                        checked={_.isNil(data.options.showButton) ? false : data.options.showButton}
                        onChange={() => handleChange('options.showButton', !data.options.showButton)} 
                        color="primary"
                        />
                    }
                    label='Exibir botão "ver mais"'
                />
                <FormHelperText>Ao clicar no botão "Ver mais" uma nova tela onde será possível navegar por todos os produtos da lista será carregada</FormHelperText>
            </FormControl>

            {data.options.showButton &&
              <FormControl fullWidth className={classes.formControl}>
                  <TextField 
                    label="Texto do botão"
                    value={data.options.buttonText || ''} 
                    onChange={(e) => handleChange('options.buttonText', e.target.value)} 
                  />
              </FormControl>
            }

          </CardContent>
      </Card>

      {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}