import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, FormControl, FormHelperText, InputLabel, Select, TextField } from '@material-ui/core';
import DeleteSectionButton from '../../DeleteSectionButton';
import ColorPicker from '../../ColorPicker';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import CategoryPicker from '../../CategoryPicker';
import { PLATFORMS, NUVEM_PRODUCT_SORT_OPTIONS, TRAY_PRODUCT_SORT_OPTIONS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function ProductCarousel({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.StoreReducer.platform);

  const sortOptions =
    platform === PLATFORMS.TRAY ?
      TRAY_PRODUCT_SORT_OPTIONS() :
      NUVEM_PRODUCT_SORT_OPTIONS


  const getSortOptionHelperText = () => {
    const sortOption = sortOptions.find(opt => opt.value === data.options.defaultSorting);

    if (!!sortOption) {
      return sortOption.helperText;
    }

    return '';
  }

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
      <Typography variant="overline" display="block" className={classes.contentTitle}>
        Configurações
        </Typography>

      <Card elevation={1} square>
        <CardContent>

          <ColorPicker
            label='Cor do título'
            initialColor={data.options.carouselTitleColor}
            onChange={(color) => handleChange('options.carouselTitleColor', color)}
          />

          <ColorPicker
            label='Cor do subtítulo'
            initialColor={data.options.carouselSubtitleColor}
            onChange={(color) => handleChange('options.carouselSubtitleColor', color)}
          />

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label="Título do carrossel"
              value={data.options.carouselTitle || ''}
              onChange={(e) => handleChange('options.carouselTitle', e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label="Subtítulo do carrossel"
              value={data.options.carouselSubtitle || ''}
              onChange={(e) => handleChange('options.carouselSubtitle', e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Alinhamento dos títulos</InputLabel>
            <Select
              native
              value={data.options.textAlign || ''}
              onChange={(e) => handleChange('options.textAlign', e.target.value)}
            >
              <option value='left'>Alinhar à esquerda</option>
              <option value='center'>Centralizar</option>
              <option value='right'>Alinhar à direita</option>
            </Select>
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Número de produtos</InputLabel>
            <Select
              native
              value={data.options.numberOfProducts}
              onChange={(e) => handleChange('options.numberOfProducts', e.target.value)}
            >
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
            </Select>
            <FormHelperText>Selecione o número de produtos que você deseja exibir no carrossel</FormHelperText>
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Ordenação</InputLabel>
            <Select
              native
              value={data.options.defaultSorting}
              onChange={(e) => handleChange('options.defaultSorting', e.target.value)}
              label="Ordenação"
            >
              {sortOptions.map((sortOption, index) => {
                return <option key={`so=${index}`} value={sortOption.value}>{sortOption.label}</option>
              })}
            </Select>
            <FormHelperText>{getSortOptionHelperText()}</FormHelperText>
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <CategoryPicker
              selectedCategoryId={data.options.categoryId || ''}
              onPicked={(category) => handleChange('options.categoryId', category.id)}
            />
            <FormHelperText>Selecione a categoria que será consultada para exibir os produtos</FormHelperText>
          </FormControl>
        </CardContent>
      </Card>

      {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}