import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SideMenu from './Editor/_components/Structure/SideMenu/SideMenu';
import MainContent from './Editor/_components/Structure/MainContent';
import { withAuthentication } from './Editor/_auth'
import { categoriesActions} from './Editor/_actions/categories.actions';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Add all Font Awesome icons
library.add(fas, far, fab)

const drawerWidth = 340;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%'
  }
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const handleSideMenuToggle = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  // Load initial configurations
  useEffect(() => {
    dispatch(categoriesActions.getAllCategories())
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <SideMenu drawerWidth={drawerWidth} isSideMenuOpen={isSideMenuOpen} handleSideMenuToggle={handleSideMenuToggle} />
      <MainContent handleSideMenuToggle={handleSideMenuToggle} />
    </div>
  );
}

export default withAuthentication(App);