import { store } from '../../index';
import  { PLATFORMS } from '../_helpers/constants';
import { categoriesService as ncs } from '../_services/nuvemshop/categories.service';
import { categoriesService as tcs} from '../_services/tray/categories.service';
import { mapNuvemCategories } from '../_helpers/mappers/nuvemshop';
import { mapTrayCategories } from '../_helpers/mappers/tray';

export const categoriesActions = {
    getAllCategories
};

function getAllCategories() {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = ncs.getAllCategories;
        mapperMethod = mapNuvemCategories;
    } else {
        queryMethod = tcs.getAllCategories;
        mapperMethod = mapTrayCategories;
    }

    return dispatch => {
        queryMethod().then(response => {
            const categories = mapperMethod(response);
            dispatch({ type: "SET_CATEGORIES", payload: categories });
        })
    };
}