import { apiFetcher } from "./apiFetcher";

export const mobilizeiStoresService = {
  getThemeById,
  setTheme,
  uploadFile
};

// ------------------
// ----- Themes -----
// ------------------

function getThemeById(platformId, storeId, themeId) {
  const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function setTheme(platformId, storeId, themeId, data) {
  const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}`

  return apiFetcher(url, {
      method: "POST",
      body: JSON.stringify(data)
  }).then(response => response);
}

// -----------------
// ----- Other -----
// -----------------

function uploadFile(platformId, storeId, data) {
  const url = `platforms/${platformId}/stores/${storeId}/upload_file`

  return apiFetcher(url, {
    method: "POST",
    body: data
  }, false).then(response => response);
}
